import React from 'react'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Yassine El Haitar</h3>
        <p className="text-lg font-normal text-gray-400">
          Mobile,Web Developer and Computer Science Teacher
        </p>
        <p className="text-base text-gray-400 tracking-wide">
          I'm a mobile and web developer. I teach computer science and programming to students and company employees.
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+212 768049050</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">contact@yassineDev.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">


          <a target="_blank" href="https://www.facebook.com/yassineDevTech" rel="noreferrer">
            <span className="bannerIcon">
              <FaFacebookF />
            </span>
          </a>

          <a target="_blank" href="https://twitter.com/yassineDevTech" rel="noreferrer">
            <span className="bannerIcon">
              <FaTwitter />
            </span>
          </a>

          <a target="_blank" href="https://linkedin.com/in/yassine-el-haitar" rel="noreferrer">
            <span className="bannerIcon">
              <FaLinkedinIn />
            </span>
          </a>





        </div>
      </div>
    </div>
  );
}

export default ContactLeft