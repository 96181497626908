import React from 'react'
//import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaHtml5, FaMicrosoft, FaServer } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { SiAdobephotoshop } from "react-icons/si";
import Title from '../layouts/Title';
import Card from './Card';

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title title="Features" des="What I Do" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="Mobile Development"
          des="Flutter and React mobile app developer, adept at creating purpose-driven apps from scratch. Proficient in crafting seamless and engaging mobile experiences."
          icon={<FaMobile />}
        />
        <Card
          title="Microsoft Developer"
          des="Develop high-quality Microsoft VBA and Power Apps to integrate various Microsoft products for a robust company data structure."
          icon={<FaMicrosoft />}
        />
        <Card
          title="Web Developer"
          des="Develop high-quality websites in diverse fields  including e-commerce, chat apps, and company management apps, using robust programming languages."
          icon={<FaHtml5 />}
        />
        <Card
          title="Server administrator"
          des="Transfer and protect data, help maintain and secure servers for companies, ensure the functionality of systems like cloud-based storage or custom operating systems. Monitor activities and audit security systems and policies."
          icon={<FaServer />}
        />
        <Card
          title="Computer Science Teacher"
          des="I Teach Programming, Computer Science, Networking and Security, and Microsoft Products for students and companies."
          icon={<GiTeacher />}
        />
        <Card
          title="Design"
          des="Design business cards, logos, banners, and mobile app interfaces."
          icon={<SiAdobephotoshop />}
        />
      </div>
    </section>
  );
}

export default Features