import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2007 - 2015</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Bachlor Mobile & Web Developer"
            subTitle="Aix-Marseille University (2014 - 2015)"
            result="4.9/5"
            des="Computer Science, Web & mobile developer"
          />
          <ResumeCard
            title="Technicien in System & Network Computing"
            subTitle="(2011 - 2013)"
            result="5/5"
            des="Diploma in Specialized Technician in System & Network Computing.."
          />
          <ResumeCard
            title="Baccalaureate in Sciences"
            subTitle="(2007 - 2010)"
            result="4.5/5"
            des="Hight School Diplomat in Sciences"
          />
        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2005 - 2010</p>
          <h2 className="text-3xl md:text-4xl font-bold">Side Experiences</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Data Entry and VBA"
            subTitle="2009 - 2010"
            result="Microsoft Office"
            des="Create Professional WorkSheets Using Excel & Visual Basic For Application."
          />
          <ResumeCard
            title="Mobile App Developer"
            subTitle="2007 - 2009"
            result="Symbian & Java"
            des="Using java to develope Mobile Apps."
          />
          <ResumeCard
            title="Video Maker"
            subTitle="2005 - 2007"
            result="After Effect Cs5"
            des="Making and Editing Videos using Adobe after effect to add special effects to the videos"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
