import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaReact } from "react-icons/fa";
import { SiMicrosoftexcel, SiFlutter, SiPowerapps } from "react-icons/si";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Professional Coder;", "Computer Science Teacher;", "Mobile & Web Developer;"],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Yassine El Haitar</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#febe10"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          As a mobile and web developer, I also serve as a computer science teacher, merging my passion for technology and AI. Committed to crafting innovative solutions and fostering a deep understanding of the digital realm in my students;
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">

            <a target="_blank" href="https://www.facebook.com/yassineDevTech" rel="noreferrer">
              <span className="bannerIcon">
                <FaFacebookF />
              </span>
            </a>

            <a target="_blank" href="https://twitter.com/yassineDevTech" rel="noreferrer">
              <span className="bannerIcon">
                <FaTwitter />
              </span>
            </a>

            <a target="_blank" href="https://linkedin.com/in/yassine-el-haitar" rel="noreferrer">
              <span className="bannerIcon">
                <FaLinkedinIn />
              </span>
            </a>

          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <SiFlutter />
            </span>
            <span className="bannerIcon">
              <FaReact />
            </span>
            <span className="bannerIcon">
              <SiMicrosoftexcel />
            </span>
            <span className="bannerIcon">
              <SiPowerapps />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftBanner