import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2015 - Now</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Software Developer & Digitization"
            subTitle="2018 -"
            result="AZURA"
            des="Software Developer, Digitization, Data Mangement,Visualizations and Repports."
          />
          <ResumeCard
            title="System Integration:"
            subTitle="2016 - 2018"
            result="HANA GLOBAL"
            des=" Designed, implemented, and maintained integrated systems"
          />
          <ResumeCard
            title="Technician in Networks and Telephone Standards"
            subTitle="2015 - 2016"
            result="TOUFNETCONNECT"
            des=" Assisted in the design, implementation, and maintenance of 
            network infrastructure, including routers, switches, and firewalls."
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2018 - Now</p>
          <h2 className="text-3xl md:text-4xl font-bold">Trainer Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Web Mobile Apps Programing - Teacher"
            subTitle="2019 - Now"
            result="AlphaCom"
            des="Teaching Students Computer Science, Programing, Database, Networks"
          />
          <ResumeCard
            title="Office Teacher"
            subTitle="2019 - 2021"
            result="Azura"
            des="Teaching Emloyees Microsoft Excel,VBA,Access, Computer Basic"
          />
          <ResumeCard
            title="Microsoft Products - Teacher"
            subTitle="2018 - 2019"
            result="IMBG"
            des="Teaching Students Microsoft Products like : Excel, VBA, Access, World"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
