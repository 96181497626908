import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree, projectFour, projectFive, projectSix } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Eva Dent Appointment"
          des=" Eva Dent App is an appointment management application for clients of a dental clinic. The application allows users to create appointments, view information about the clinic, as well as discover the services and promotions offered. The app was created using Java programming language for Android, with a Google Firebase database. Additionally, there is an administration app linked to the client application for managing appointments."
          src={projectOne}
        />
        <ProjectsCard
          title="City eye"
          des=" city repports"
          src={projectTwo}

        />
        <ProjectsCard
          title="study in russia"
          des=" study abroad app for student"
          src={projectThree}
          linkWeb="https://studyinrussia.ma/"
        />
        <ProjectsCard
          title="2Doz Chating App"
          des=" Chating app using swipe and voice chat"
          src={projectFour}
          linkGit="https://github.com/moroccan-coder/ChatApp-2Doz"
        />
        <ProjectsCard
          title="City eye Admin"
          des=" Admin panel for city eye app"
          src={projectFive}
        />
        <ProjectsCard
          title="Other Apps"
          des=" Check my github to see all my work"
          src={projectSix}
        />
      </div>
    </section>
  );
}

export default Projects