
const OpenEmailButton = () => {

    console.log("tessssst function");


    const emailAddress = 'contact@yassinedev.com';
    const subject = 'YassineDev Service';
    const body = 'Body of the email';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email application
    window.location.href = mailtoLink;
};

export default OpenEmailButton;
